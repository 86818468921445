import { GeneratedType } from '@cosmjs/proto-signing';
import {
  MsgDeleteAccount,
  MsgDeleteDomain,
  MsgRegisterAccount,
  MsgRegisterDomain,
  MsgRenewAccount,
  MsgRenewDomain,
  MsgReplaceAccountMetadata,
  MsgReplaceAccountResources,
  MsgTransferAccount,
  MsgTransferDomain,
} from 'proto/tx';

export enum Starname {
  RegisterAccount = '/starnamed.x.starname.v1beta1.MsgRegisterAccount',
  RegisterDomain = '/starnamed.x.starname.v1beta1.MsgRegisterDomain',
  TransferAccount = '/starnamed.x.starname.v1beta1.MsgTransferAccount',
  DeleteAccount = '/starnamed.x.starname.v1beta1.MsgDeleteAccount',
  RenewAccount = '/starnamed.x.starname.v1beta1.MsgRenewAccount',
  RenewDomain = '/starnamed.x.starname.v1beta1.MsgRenewDomain',
  ReplaceAccountMetadata = '/starnamed.x.starname.v1beta1.MsgReplaceAccountMetadata',
  ReplaceAccountResources = '/starnamed.x.starname.v1beta1.MsgReplaceAccountResources',
  TransferDomain = '/starnamed.x.starname.v1beta1.MsgTransferDomain',
  DeleteDomain = '/starnamed.x.starname.v1beta1.MsgDeleteDomain',
}

import { MsgSend } from 'cosmjs-types/cosmos/bank/v1beta1/tx';

export const TxType = { Starname };

export const starnameTypes: Iterable<[string, GeneratedType]> = Object.entries({
  '/cosmos.bank.v1beta1.MsgSend': MsgSend,
  // Account
  [Starname.RegisterAccount]: MsgRegisterAccount,
  [Starname.RenewAccount]: MsgRenewAccount,
  [Starname.DeleteAccount]: MsgDeleteAccount,
  [Starname.TransferAccount]: MsgTransferAccount,
  [Starname.ReplaceAccountResources]: MsgReplaceAccountResources,
  [Starname.ReplaceAccountMetadata]: MsgReplaceAccountMetadata,
  // Domain
  [Starname.RegisterDomain]: MsgRegisterDomain,
  [Starname.RenewDomain]: MsgRenewDomain,
  [Starname.TransferDomain]: MsgTransferDomain,
  [Starname.DeleteDomain]: MsgDeleteDomain,
});
