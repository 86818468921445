import 'assets/index.css';
import 'index.css';

import { AccAddress } from '@chainapsis/cosmosjs/common/address';
import { defaultBech32Config } from '@chainapsis/cosmosjs/core/bech32Config';
import { AccountData, EncodeObject, Registry } from '@cosmjs/proto-signing';
import {
  AminoTypes,
  Coin,
  isDeliverTxSuccess,
  SigningStargateClient,
  StargateClient,
  StdFee,
} from '@cosmjs/stargate';
import { BulkActionButton, Entry } from 'components/bulkActionButton';
import config from 'config';
import locale from 'locale';
import classes from 'names-application.module.scss';
import { MsgDeleteAccount, MsgRegisterAccount } from 'proto/tx';
import { Account, Resource } from 'proto/types';
import React from 'react';
import { DomainWitAccounts, ownerDomains } from 'starnameApi';
import { starnameTypes, TxType } from 'starnameRegistry';
import { aminoTypes } from 'types/aminoTypes';
import { ProcessingState } from 'types/processingState';
import { Tx } from 'types/tx';
import { estimateFee } from 'utils/estimateFee';

const registry = new Registry(starnameTypes);

function NamesApplication(): React.ReactElement {
  const [account, setAccount] = React.useState<AccountData | null>(null);
  const [currentBalance, setCurrentBalance] = React.useState<number>(0);
  const [signingClient, setSigningClient] = React.useState<SigningStargateClient | null>(null);
  const [processingState, setProcessingState] = React.useState<ProcessingState>(
    ProcessingState.idle()
  );
  const [transactionHash, setTransactionHash] = React.useState<string | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [chainId, setChainId] = React.useState<string>('');
  const [domains, setDomains] = React.useState<readonly DomainWitAccounts[]>([]);

  const resetAllMessages = React.useCallback((): void => {
    setTransactionHash(null);
    setErrorMessage(null);
  }, []);

  const buildDeleteTx = React.useCallback(
    (entry: Entry): Tx<MsgDeleteAccount> => {
      if (account === null) {
        throw new Error('application not correctly initialized');
      }

      return {
        typeUrl: TxType.Starname.DeleteAccount,
        value: {
          name: entry.name,
          domain: entry.domain,
          // WARNING: This is confusing because we create the starname with a given owner
          //          which is not `account.address' and then delete with `account.address'
          //          because it is supposed to be the owner of the account
          owner: account.address,
          payer: '',
        },
      };
    },
    [account]
  );

  const buildRegisterTx = React.useCallback(
    (entry: Entry): Tx<MsgRegisterAccount> => {
      if (account === null) {
        throw new Error('application not correctly initialized');
      }
      const resources = assetsToResources(entry.assets);

      return {
        typeUrl: TxType.Starname.RegisterAccount,
        value: {
          name: entry.name,
          domain: entry.domain,
          owner: entry.owner,
          registerer: account.address,
          broker: account.address,
          payer: '',
          resources: resources,
        },
      };
    },
    [account]
  );

  const reloadDomains = React.useCallback(async (): Promise<void> => {
    if (!account) {
      return;
    }

    setDomains(await ownerDomains(account.address));
  }, [account]);

  const handleMessages = React.useCallback(
    async (messages: readonly EncodeObject[]): Promise<void> => {
      if (signingClient === null || account === null) {
        throw new Error('application not correctly initialized');
      }
      setErrorMessage(null);
      setTransactionHash(null);

      const fee: StdFee = estimateFee(messages);
      setProcessingState(ProcessingState.busy());
      try {
        const txResult = await signingClient.signAndBroadcast(account.address, messages, fee, '');

        if (isDeliverTxSuccess(txResult)) {
          await reloadDomains();
          setTransactionHash(txResult.transactionHash);
        } else if (txResult.rawLog) {
          setErrorMessage(txResult.rawLog);
        } else {
          setErrorMessage('Error Desconocido');
        }
      } catch (error: any) {
        setErrorMessage(error.toString());
      } finally {
        setProcessingState(ProcessingState.idle());
      }
    },
    [account, reloadDomains, signingClient]
  );

  const handleRegisterNames = React.useCallback(
    (entries: readonly Entry[]): void => {
      if (account === null) {
        throw new Error('application not correctly initialized');
      }

      void handleMessages(entries.map(buildRegisterTx));
    },
    [account, buildRegisterTx, handleMessages]
  );

  const handleTransferNames = React.useCallback(
    (entries: readonly Entry[]): void => {
      if (account === null) {
        throw new Error('application not correctly initialized');
      }

      void handleMessages(
        entries
          .map((entry: Entry): [Tx<MsgDeleteAccount>, Tx<MsgRegisterAccount>] => {
            return [buildDeleteTx(entry), buildRegisterTx(entry)];
          })
          .reduce(
            (
              combined: readonly EncodeObject[],
              pair: [Tx<MsgDeleteAccount>, Tx<MsgRegisterAccount>]
            ): readonly EncodeObject[] => [...combined, ...pair],
            []
          )
      );
    },
    [account, buildDeleteTx, buildRegisterTx, handleMessages]
  );

  const handleDeleteNames = React.useCallback(
    (entries: readonly Entry[]): void => {
      if (account === null) {
        throw new Error('application not correctly initialized');
      }

      void handleMessages(entries.map(buildDeleteTx));
    },
    [account, buildDeleteTx, handleMessages]
  );

  React.useEffect((): void => {
    StargateClient.connect(config.rpcUrl)
      .then((client: StargateClient): Promise<string> => client.getChainId())
      .then(setChainId)
      .catch(console.warn);
  }, []);

  React.useEffect((): void => {
    reloadDomains();
  }, [reloadDomains]);

  const initialize = React.useCallback(async (): Promise<void> => {
    const { keplr } = window;

    if (chainId === '') {
      return;
    }

    if (keplr) {
      const defaultAsset = config.mainAsset;
      const coin = {
        coinDenom: defaultAsset.symbol,
        coinMinimalDenom: defaultAsset.denom,
        coinDecimals: 6,
      };

      if (chainId === 'local-starname') {
        try {
          keplr.experimentalSuggestChain({
            chainId: chainId,
            chainName: 'Starname Local',
            rpc: config.rpcUrl,
            rest: config.apiUrl,
            stakeCurrency: {
              ...coin,
              coinDenom: 'stake',
              coinMinimalDenom: 'ustake',
            },
            bip44: {
              coinType: 234,
            },
            bech32Config: defaultBech32Config('star'),
            currencies: [coin],
            feeCurrencies: [
              {
                ...coin,
                gasPriceStep: {
                  low: 1,
                  average: 2,
                  high: 4,
                },
              },
            ],
          });
        } catch (error) {
          console.error(error);
        }
      }
      keplr.enable([chainId]);

      const signer = keplr.getOfflineSigner(chainId);
      const accounts = await signer.getAccounts();
      if (accounts.length < 1) {
        throw new Error('insufficient number of accounts');
      }

      const signingClient = await SigningStargateClient.connectWithSigner(config.rpcUrl, signer, {
        registry: registry,
        aminoTypes: new AminoTypes(aminoTypes),
      });

      const balances = await signingClient.getAllBalances(accounts[0].address);
      const matchingBalance = balances.find(
        (entry: Coin): boolean => entry.denom === defaultAsset.denom
      );
      if (matchingBalance === undefined) {
        throw new Error('application configuration seems to be wrong');
      }

      setSigningClient(signingClient);
      setCurrentBalance(Number(matchingBalance.amount) / 1000000);
      setAccount(accounts[0]);
    }
  }, [chainId]);

  React.useEffect((): void => {
    setProcessingState(ProcessingState.busy());

    initialize()
      .then((): void => {
        setProcessingState(ProcessingState.idle());
      })
      .catch((error: Error | string): void => {
        setProcessingState(ProcessingState.error(error));
      });
  }, [initialize]);

  return (
    <div className={ProcessingState.isBusy(processingState) ? classes.busy : undefined}>
      <header className={classes.header}>
        <div className={classes.applicationName}>{locale.Main.ApplicationName}</div>
        <div className={classes.balance}>{account?.address}</div>
        <div className={classes.balance}>Balance: {currentBalance} IOV</div>
      </header>

      <main className={classes.content}>
        <div className={classes.panels}>
          <div className={classes.actions}>
            <BulkActionButton
              icon="create"
              label={locale.Main.Register}
              description={locale.Main.RegisterDescription}
              onActionLoaded={handleRegisterNames}
            />
            <BulkActionButton
              icon="transfer"
              label={locale.Main.Transfer}
              description={locale.Main.TransferDescription}
              onActionLoaded={handleTransferNames}
            />
            <BulkActionButton
              icon="delete"
              label={locale.Main.Delete}
              description={locale.Main.DeleteDescription}
              onActionLoaded={handleDeleteNames}
            />
            {errorMessage && (
              <div className={classes.errorMessage}>
                <div>{errorMessage}</div>
                <div className={classes.closeButton} onClick={resetAllMessages}>
                  <i className="fa fa-close" />
                </div>
              </div>
            )}
            {transactionHash && (
              <div className={classes.successMessage}>
                <div>
                  Transacci&oacute;n processada con &eacute;xito{' '}
                  <a
                    href={`https://www.mintscan.io/starname/txs/${transactionHash}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ver en el explorador
                  </a>
                </div>
                <div className={classes.closeButton}>
                  <i className="fa fa-close" onClick={resetAllMessages} />
                </div>
              </div>
            )}
          </div>
          <div className={classes.namesTree}>
            {domains.map((domain: DomainWitAccounts): React.ReactElement => {
              const { accounts } = domain;

              return (
                <div key={domain.name}>
                  <h2>*{domain.name}</h2>
                  {accounts.length === 1 ? (
                    <div className={classes.emptyDomain}>No hay starnames</div>
                  ) : (
                    <ul>
                      {accounts
                        .filter((account: Account): boolean => account.name !== '')
                        .map((account: Account): React.ReactElement => {
                          return (
                            <li key={account.name}>
                              <div className={classes.title}>
                                <h3>
                                  {account.name}
                                  <span className={classes.domain}>*{domain.name}</span>
                                </h3>
                                <div>({account.resources.length} resources)</div>
                              </div>
                              <div className={classes.address}>
                                {new AccAddress(account.owner, 'star').toBech32()}
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </main>
    </div>
  );
}

export default NamesApplication;

const assetsToResources = (assets: { [key: string]: string }): Resource[] =>
  Object.entries(assets)
    .map(([token, address]: [string, string]): Resource => {
      return {
        uri: 'asset:' + token,
        resource: address,
      };
    })
    .filter((resource: Resource): boolean => resource.resource !== '');
