import classes from 'components/file-opener.module.scss';
import locale from 'locale';
import React, { useCallback } from 'react';

export interface Entry {
  readonly name: string;
  readonly domain: string;
  readonly owner: string;

  readonly assets: { [token: string]: string };
}

type ButtonIcon = 'create' | 'transfer' | 'delete';

interface Props {
  readonly label: string;
  readonly icon: ButtonIcon;
  readonly description: string;

  onActionLoaded(entries: readonly Entry[]): void;
}

export const BulkActionButton: React.FC<Props> = ({
  description,
  icon,
  label,

  onActionLoaded,
}: Props): React.ReactElement => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [dragging, setDragging] = React.useState<boolean>(false);
  const [currentUrl, setCurrentUrl] = React.useState<string>('');

  const triggerLoadedEvent = useCallback(
    (entries: any): void => {
      onActionLoaded(
        entries.map((entry: any): Entry => {
          const { name, domain, owner, ...assets } = entry;
          return {
            name: name,
            domain: domain,
            owner: owner,
            assets: assets,
          };
        })
      );
    },
    [onActionLoaded]
  );

  const readFile = React.useCallback(
    (file: File): void => {
      const reader = new FileReader();
      reader.onloadend = (): void => {
        const text = reader.result as string;
        const entries = JSON.parse(text);

        triggerLoadedEvent(entries);
      };

      reader.readAsText(file);
    },
    [triggerLoadedEvent]
  );

  const handleDownloadUrl = React.useCallback((): void => {
    fetch(currentUrl)
      .then((response: Response): Promise<any> => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error('cannot load the data');
        }
      })
      .then(triggerLoadedEvent);
  }, [currentUrl, triggerLoadedEvent]);

  const handleUrlChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setCurrentUrl(value);
  }, []);

  const handleFileInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { files } = event.target;
      if (files && files.length > 0) {
        readFile(files[0]);
      }
    },
    [readFile]
  );

  const handleDrop = React.useCallback(
    (event: React.DragEvent): void => {
      const { files } = event.dataTransfer;
      event.preventDefault();
      readFile(files[0]);
    },
    [readFile]
  );

  const handleClick = React.useCallback((): void => {
    const { current: input } = inputRef;
    if (input !== null) {
      input.click();
    }
  }, []);

  const handleDragOver = React.useCallback((event: React.DragEvent): void => {
    event.preventDefault();
    setDragging(true);
  }, []);

  const preventClickPropagation = React.useCallback((event: React.MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  return (
    <div
      className={[classes.container, dragging ? classes.dragging : undefined].join(' ')}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onClick={handleClick}
    >
      <h3>
        <i className={iconsMap[icon]} />
        {label}
      </h3>
      <p>{description}</p>
      <input ref={inputRef} type="file" onChange={handleFileInputChange} />
      <div className={classes.urlInputContainer} onClick={preventClickPropagation}>
        <input
          type="text"
          placeholder={locale.Main.OrTypeUrlHere}
          value={currentUrl}
          onInput={handleUrlChange}
        />
        <button onClick={handleDownloadUrl}>
          <span>{locale.Main.Download}</span>
        </button>
      </div>
    </div>
  );
};

const iconsMap: { [key in ButtonIcon]: string } = {
  create: 'fa fa-plus-circle fa-fw',
  transfer: 'fa fa-left-right fa-fw',
  delete: 'fa fa-trash-alt fa-fw',
};
