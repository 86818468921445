import { EncodeObject } from '@cosmjs/proto-signing';
import { StdFee } from '@cosmjs/stargate';
import config from 'config';
import { TxType } from 'starnameRegistry';

const getResourcesCount = (msg: EncodeObject): number => {
  const { value } = msg;
  if ('newResources' in value) {
    const { newResources } = value;
    return newResources.length;
  } else {
    throw new Error('not a set resources message');
  }
};

const gasMap = {
  '/starnamed.x.starname.v1beta1.MsgRenewDomain': 450000,
  '/starnamed.x.starname.v1beta1.MsgReplaceAccountResources': 40000,
  '/starnamed.x.starname.v1beta1.MsgTransferDomain': 450000,
  '/cosmos.bank.v1beta1.MsgSend': 400000,
  default: 400000,
};

const isKeyOf = <T>(key: string | keyof T, object: T): key is keyof T => {
  return <string>key in object;
};

export const estimateFee = (msgs: readonly EncodeObject[]): StdFee => {
  const { gasPrice } = config;

  const totalGas = msgs.reduce((totalGas: number, msg: EncodeObject): number => {
    const msgType = msg.typeUrl;
    if (msgType === TxType.Starname.ReplaceAccountResources) {
      const msgGas: number | undefined = gasMap[msgType];
      if (msgGas === undefined) {
        return 0;
      }

      return Math.max(msgGas * getResourcesCount(msg) + gasMap.default / 2, gasMap.default / 2);
    } else if (isKeyOf(msgType, gasMap)) {
      return gasMap[msgType];
    } else {
      return gasMap.default;
    }
  }, 0);

  return {
    amount: [
      {
        denom: gasPrice.denom,
        amount: (Number(gasPrice.amount) * totalGas).toFixed(0),
      },
    ],
    gas: totalGas.toFixed(0),
  };
};
