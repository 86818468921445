enum State {
  idle,
  busy,
  error,
}

export interface ProcessingState {
  readonly state: State;
}

export interface ProcessingStateIdle extends ProcessingState {
  readonly state: State.idle;
}

export interface ProcessingStateBusy extends ProcessingState {
  readonly state: State.busy;
  readonly message?: string;
}

export interface ProcessingStateError extends ProcessingState {
  readonly state: State.error;
  readonly error: Error | string;
}

export class ProcessingState {
  public static idle(): ProcessingStateIdle {
    return {
      state: State.idle,
    };
  }

  public static error(error: Error | string): ProcessingStateError {
    return {
      state: State.error,
      error: error,
    };
  }

  public static busy(message?: string): ProcessingStateBusy {
    return {
      state: State.busy,
      message: message,
    };
  }

  public static isIdle(processingState: ProcessingState): processingState is ProcessingStateIdle {
    return processingState.state === State.idle;
  }

  public static isBusy(processingState: ProcessingState): processingState is ProcessingStateBusy {
    return processingState.state === State.busy;
  }

  public static isError(processingState: ProcessingState): processingState is ProcessingStateError {
    return processingState.state === State.error;
  }
}
