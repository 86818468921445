import en from 'locale/en.json';
import es from 'locale/es.json';

interface ApplicationStrings {
  readonly Main: {
    readonly ApplicationName: string;
    readonly Register: string;
    readonly RegisterDescription: string;
    readonly Transfer: string;
    readonly TransferDescription: string;
    readonly Delete: string;
    readonly DeleteDescription: string;
    readonly OrTypeUrlHere: string;
    readonly Download: string;
  };
}

const language = navigator.language ?? 'en';

export default ((): ApplicationStrings => {
  if (language.startsWith('es')) {
    return es;
  } else {
    return en;
  }
})();
