interface Asset {
  readonly denom: string;
  readonly symbol: string;
}

interface Amount {
  readonly denom: string;
  readonly amount: string;
}

const defaultAsset: Asset = {
  denom: 'uiov',
  symbol: 'IOV',
};

const gasPrice: Amount = { denom: defaultAsset.denom, amount: '16' };

interface Config {
  readonly apiUrl: string;
  readonly rpcUrl: string;
  readonly mainAsset: Asset;
  readonly gasPrice: Amount;
}

const config: Config = {
  apiUrl: 'https://lcd-iov.keplr.app',
  rpcUrl: 'https://rpc.cosmos.directory/starname',
  mainAsset: defaultAsset,
  gasPrice: gasPrice,
};

export default config;
