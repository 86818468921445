import config from 'config';
import {
  QueryDomainAccountsRequest,
  QueryDomainAccountsResponse,
  QueryOwnerDomainsRequest,
  QueryOwnerDomainsResponse,
} from 'proto/query';
import { Account, Domain } from 'proto/types';

export interface DomainWitAccounts extends Domain {
  accounts: Account[];
}
const base64ToUint8Array = (base64String: string): Uint8Array =>
  Uint8Array.from(window.atob(base64String), (s: string): number => s.charCodeAt(0));

const toHex = (bytes: Uint8Array): string =>
  bytes.reduce((hex: string, byte: number): string => hex + byte.toString(16).padStart(2, '0'), '');

const domainAccounts = async (domain: string): Promise<Account[]> => {
  const data = toHex(
    QueryDomainAccountsRequest.encode({
      domain: domain,
      pagination: undefined,
    }).finish()
  );

  const response: Response = await fetch(`${config.rpcUrl}`, {
    method: 'POST',
    body: JSON.stringify({
      id: 0,
      jsonrpc: '2.0',
      method: 'abci_query',
      params: {
        path: '/starnamed.x.starname.v1beta1.Query/DomainAccounts',
        data: data,
      },
    }),
  });
  const responseObject = await response.json();

  const {
    response: { value },
  } = responseObject.result;

  const decoded = QueryDomainAccountsResponse.decode(base64ToUint8Array(value));

  return decoded.accounts;
};

export const ownerDomains = async (owner: string): Promise<readonly DomainWitAccounts[]> => {
  const data = toHex(
    QueryOwnerDomainsRequest.encode({
      owner: owner,
      pagination: undefined,
    }).finish()
  );

  const response: Response = await fetch(config.rpcUrl, {
    method: 'POST',
    body: JSON.stringify({
      id: 0,
      jsonrpc: '2.0',
      method: 'abci_query',
      params: {
        path: '/starnamed.x.starname.v1beta1.Query/OwnerDomains',
        data: data,
      },
    }),
  });
  const responseObject = await response.json();

  const {
    response: { value },
  } = responseObject.result;
  const decoded = QueryOwnerDomainsResponse.decode(base64ToUint8Array(value));

  return Promise.all(
    decoded.domains.map(async (domain: Domain): Promise<DomainWitAccounts> => {
      return { ...domain, accounts: await domainAccounts(domain.name) };
    })
  );
};
